

import { useCallback,useState } from "react";
import { RatingReasons, RatingsStars } from "./component"
import { Button } from "@mui/material";
import axios from "axios";


const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}`

export default function Rating({closeFunction,callId}){

    const [ratings,setRatings] = useState(null);
    const [reason,setReason] = useState('');
    const [submitting,setSubmitting] = useState(false);

    const saveRatings = useCallback(function(value){
        console.log("the rating is ",value);
        setRatings(()=>value);
    },[])

    function handleSubmitClick(){
        const accessToken = localStorage.getItem('authAccessToken');
        setSubmitting(()=>true);
        if(accessToken){
            try{
                axios.post(`${baseUrl}/calls/${callId}/rate/`,{
                    rating:ratings,
                    reason:reason
                },{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                })
                .then((response)=>{
                    setSubmitting(()=>false);
                    window.history.back()
                    return response
                })
                .catch(error=>{
                    setSubmitting(()=>false);
                    window.history.back()
                    console.log(error)})
            }catch(error){

            }
        }
    }

    function handleCloseClick(){
        closeFunction()
    }

    function handleReasonChange(value){
        setReason(()=>value)
    }

    return <div className="z-[50] bg-slate-50 fixed bottom-0 left-0 rounded-2xl gap-4 border-solid border-[0.02rem] shadow-lg border-slate-400 p-4 w-full flex flex-col items-center justify-center">
        <p className="font-semibold text-xl">Give Us Feedback</p >
        <p className="w-80% text-center text-sm">How was the call?</p>
        <RatingsStars saveRatings={saveRatings} />
        <RatingReasons  handleChange={handleReasonChange} />
        <div className="flex w-full justify-around">
            <Button variant="contained" sx={{backgroundColor:"black"}} onClick = {handleCloseClick} >Close</Button>
            <Button variant="contained" sx={{backgroundColor:`${submitting ?"grey":"green"}`}} onClick = {handleSubmitClick}>Submit</Button>
            </div>
    </div>
}