import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { numberWithCommas } from '../utils';
import { format } from 'date-fns';
import TableRowsSelect from '../TableSelectRows';

const isProd = window.location.hostname === "requestmechanic.com";

const baseUrl = `${
  isProd
    ? "https://requestnowmechanic.com/v1"
    : "https://requestnowmechanic.com/staging/v1"
}`;

export const Requests = () => {
  const { email } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = parseInt(searchParams.get("limit")) || 10; 
  const [userRequest, setUserRequest] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  console.log(email);

  const fetchUserRequest = async () => {
    const accessToken = localStorage.getItem("authAccessToken");

    try {
      const response = await axios.get(
        `${baseUrl}/client/user-requests/?email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Transaction History Response:", response.data.data);
      setUserRequest(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserRequest();
  }, [email]);

  // Calculate total pages
  const totalPages = Math.ceil(userRequest.length / limit);

  // Get the data for the current page
  const currentData = userRequest.slice(
    (currentPage - 1) * limit,
    currentPage * limit
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (value) => {
    setSearchParams({ currentPage, limit: value });
    console.log(value);
  };

  return (
    <RequestView>
      <div className="overflow-x-auto">
        <table className="min-w-full border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-4 text-left">User</th>
              <th className="p-4 text-left">Mechanic</th>
              <th className="p-4 text-left">Car Brand</th>
              <th className="p-4 text-left">Amount</th>
              <th className="p-4 text-left">Date</th>
            </tr>
          </thead>
          <tbody>
            {/* {userRequest.map((request) => ( */}
            {currentData.map((request) => (
              <tr key={request?.id} className="bg-white">
                <td className="p-4 capitalize">
                  {request?.user?.first_name || "-"}{" "}
                  {request?.user?.last_name || "-"}
                </td>
                <td className="p-4 capitalize">
                  {request?.mechanic?.first_name || "-"}{" "}
                  {request?.mechanic?.last_name || "-"}
                </td>
                <td className="p-4">{request?.car_brand}</td>
                <td className="p-4">
                  ₦{numberWithCommas(request?.mechanic?.rate || "00.00")}
                </td>
                <td className="p-4">{request?.date || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PaginationView>
        <TableRowsSelect onChange={handleRowsPerPageChange} />

        <div className="paginate">
          <button
            className="prev"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <span>
            Page {currentPage} of {Math.ceil(userRequest.length / limit)}
          </span>
          <button
            className="next"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </PaginationView>
    </RequestView>
  );
}

const RequestView = styled.div`
  height: 100%;
  margin: 0 1rem;
  padding-bottom: 2rem;
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 42px;
      height: 42px;
      border: none;
      outline: none;
      background-color: rgb(209 213 219);
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: rgb(209 213 220);
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;