import { Button, TextField, Alert } from "@mui/material";
import { ArrowLeft2, Edit } from "iconsax-react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const isProd = window.location.hostname === "requestmechanic.com";

const baseUrl = `${
  isProd
    ? "https://requestnowmechanic.com/v1"
    : "https://requestnowmechanic.com/staging/v1"
}`;

export default function PersonalInfo() {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [DOB, setDOB] = useState("");
  const [location, setLocation] = useState("");
  const [editName, setEditName] = useState(false);
  const [editNumber, setEditNumber] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const [updating, setUpdating] = useState(false);
  // const [isActive, setIsActive] = useState(true);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(function () {
    const userEmail = localStorage.getItem("userEmail");
    const accessToken = localStorage.getItem("authAccessToken");
    if (accessToken) {
      try {
        axios
          .get(`${baseUrl}/user/user/${userEmail}/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(function (response) {
            setfirstname((init) => response.data.data.first_name);
            setlastname((init) => response.data.data.last_name);
            setEmail((init) => response.data.data.email);
            setNumber((init) => response.data.data.phone_number);
            setDOB((init) => response.data.data.date_of_birth);
            setLocation((init) => response.data.data.location);
            console.log(
              "personal profile page query response",
              response.data.data.phone_number
            );
            return response;
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch {
        console.log("an error occured while fetching profile data");
      }
    }
  }, []);

  function handleNameEdit() {
    setEditName((init) => true);
    setEditNumber((init) => false);
    setEditEmail((init) => false);
  }

  function handleNumberEdit() {
    setEditNumber((init) => true);
    setEditName((init) => false);
    setEditEmail((init) => false);
  }

  function handleEmailEdit() {
    setEditEmail((init) => true);
    setEditNumber((init) => false);
    setEditName((init) => false);
  }

  function handleLocationEdit() {
    setEditLocation((init) => true);
    setEditNumber((init) => false);
    setEditName((init) => false);
    setEditEmail((init) => false);
  }

  function handleUpdate() {
    console.log({
      first_name: firstname,
      last_name: lastname,
      email: email,
      phone_number: Number(to234(number)),
    });
    setUpdating((init) => true);
    const accessToken = localStorage.getItem("authAccessToken");
    if (accessToken) {
      try {
        axios
          .put(
            `${baseUrl}/user/registration/step2/`,
            {
              first_name: firstname,
              last_name: lastname,
              email: email,
              phone_number: Number(to234(number)),
              location: location,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          )
          .then(function (response) {
            setUpdating((init) => false);
            handleBackdropClick();
            console.log("put profile page  response", response);
          });
      } catch {
        setUpdating((init) => false);
        handleBackdropClick();
        console.log("an error occured while fetching profile data");
      }
    }
  }

  const handleLocationUpdate = () => {
    setUpdating((init) => true);
    const accessToken = localStorage.getItem("authAccessToken");
    if (accessToken) {
      try {
        axios.put(
          `${baseUrl}/user/registration/step3/mechanic/`,
          { workshop_location: location, update_is_active: true },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        ).then(function () {
          setUpdating((init) => false);
          handleBackdropClick();
        });
      } catch (error) {
        console.log(error);
        handleBackdropClick();
      }
    }
  }

  function handleBackdropClick() {
    setEditName((init) => false);
    setEditNumber((init) => false);
    setEditEmail((init) => false);
    setEditLocation((init) => false);
  }

  function handleBack() {
    window.history.back();
  }

  function to234(number) {
    const stringifiedValue = String(number);
    switch (true) {
      case stringifiedValue.startsWith("0"): {
        const reduced = stringifiedValue.slice(1);
        const built = `234${reduced}`;
        return built;
      }
      case stringifiedValue.startsWith("+234"): {
        const reduced = stringifiedValue.slice(1);
        return reduced;
      }
      case stringifiedValue.startsWith("234"):
        return stringifiedValue;

      default:
        break;
    }
  }

  return (
    <div className="px-7 py-4 relative h-screen overflow-hidden">
      <p className="font-bold flex gap-2 text-2xl items-center">
        <ArrowLeft2 onClick={handleBack} className="font-normal" /> Personal
        Information
      </p>
      <div className="mt-4 flex flex-col gap-4">
        <Bioitem
          title={"Account Name"}
          currentValue={`${firstname} ${lastname}`}
          handleEdit={handleNameEdit}
        />
        <Bioitem
          title={"Phone Number"}
          currentValue={number}
          handleEdit={handleNumberEdit}
        />
        <Bioitem
          title={"Email"}
          currentValue={email}
          handleEdit={handleEmailEdit}
        />
        <Bioitem
          title={"Date of Birth"}
          currentValue={DOB}
          nonEditable={true}
        />
        <Bioitem
          title={"Location"}
          currentValue={location}
          handleEdit={handleLocationEdit}
        />
        {(editName || editNumber || editEmail || editLocation) && (
          <BackdropClose handleClick={handleBackdropClick} />
        )}
        <NameEditor
          handleLastnameChange={(value) => setlastname((init) => value)}
          handlefirstnameChange={(value) => setfirstname((init) => value)}
          isUpdating={updating}
          handleUpdate={handleUpdate}
          show={editName}
          firstname={firstname}
          lastname={lastname}
        />
        <Editor
          type={"tel"}
          handleChange={(value) => setNumber((init) => value)}
          isUpdating={updating}
          handleUpdate={handleUpdate}
          label={"Phone Number"}
          show={editNumber}
          placeholder={number}
        />
        <Editor
          type={"email"}
          handleChange={(value) => setEmail((init) => value)}
          isUpdating={updating}
          handleUpdate={handleUpdate}
          label={"Email"}
          show={editEmail}
          placeholder={email}
        />
        <Editor
          type={"text"}
          handleChange={(value) => setLocation((init) => value)}
          isUpdating={updating}
          handleUpdate={handleLocationUpdate}
          label={"Location"}
          show={editLocation}
          placeholder={location}
        />
        {/* <Editor type={"password"}  handleChange={handlePasswordChange} isUpdating={updating} handleUpdate={handleAccountDelete} label={"Enter Your Password"} show={editEmail} placeholder={email} /> */}
        {/* {passwordPopup && <PasswordEntry isUpdating={updating} handleChange={handlePasswordChange} handledelete={handleAccountDelete} />} */}
        {/* <Button variant="contained" sx={{position:"relative",left:"5%",backgroundColor:"red",width:"90%",marginTop:"2rem"}} className="z-0" onClick={handleDeleteClick}>DELETE ACCOUNT</Button> */}
      </div>
    </div>
  );
}

function Bioitem({ title, currentValue, nonEditable, handleEdit }) {
  return (
    <div className="bg-zinc-200 rounded-lg flex items-center justify-between px-4 py-2 border-[0.02rem] border-zinc-600 border-solid ">
      <div className="max-w-[70%]">
        <p className="font-bold">{title}</p>
        <p className="py-1 max-w-[100%] overflow-hidden text-ellipsis">
          {currentValue}
        </p>
      </div>
      {!nonEditable && <Edit onClick={handleEdit} className="text-greeen" />}
    </div>
  );
}

function Editor({
  placeholder,
  show,
  label,
  handleUpdate,
  isUpdating,
  handleChange,
  type,
}) {
  return (
    <div
      className={`h-fit flex flex-col   p-4 w-screen bg-white  absolute rounded-t-2xl left-0 ${
        show ? "bottom-[0vh]" : "bottom-[-100vh]"
      } `}
    >
      <p className="font-bold mb-2">{label}</p>
      <TextField
        type={type || "name"}
        onChange={(e) => handleChange(e.target.value)}
        className="mb-4"
        placeholder={placeholder}
      />
      <Button
        variant="contained"
        onClick={handleUpdate}
        sx={{ backgroundColor: "green" }}
        className="bg-green-500 text-white"
      >
        {isUpdating ? "Updating" : "Update"}
      </Button>
    </div>
  );
}

function NameEditor({
  firstname,
  lastname,
  show,
  handleUpdate,
  isUpdating,
  handlefirstnameChange,
  handleLastnameChange,
}) {
  return (
    <div
      className={`h-fit flex flex-col  p-4 w-screen bg-white absolute rounded-t-lg left-0 ${
        show ? "bottom-[0vh]" : "bottom-[-100vh]"
      } `}
    >
      <p className="font-bold mb-2">First Name</p>
      <TextField
        onChange={(e) => handlefirstnameChange(e.target.value)}
        className="mb-4"
        placeholder={firstname}
      />
      <p className="font-bold mb-2">Last Name</p>
      <TextField
        onChange={(e) => handlefirstnameChange(e.target.value)}
        className="mb-4"
        placeholder={lastname}
      />
      <Button
        disabled={isUpdating}
        onClick={handleUpdate}
        sx={{ backgroundColor: "green" }}
        variant="contained"
        className="bg-green-500 text-white"
      >
        {isUpdating ? "Updating" : "Update"}
      </Button>
    </div>
  );
}

function BackdropClose({ handleClick }) {
  return (
    <div
      onClick={handleClick}
      className="w-screen h-screen bg-black opacity-10 absolute top-0 left-0"
    ></div>
  );
}

function PasswordEntry({
  show,
  label,
  handledelete,
  isUpdating,
  handleChange,
  type,
}) {
  return (
    <div
      className={`z-20 max-h-[55vh] flex flex-col   p-4 w-screen bg-white  absolute rounded-t-2xl left-0 bottom-[0vh] `}
    >
      <TextField
        type={type || "name"}
        onChange={(e) => handleChange(e.target.value)}
        className="mb-4"
        placeholder={"Enter your Password"}
      />
      <Button
        variant="contained"
        onClick={handledelete}
        sx={{ backgroundColor: "green" }}
        className={`${isUpdating ? "bg-slate-400" : "bg-green-500"} text-white`}
      >
        {isUpdating ? "deleting..." : "Continue"}
      </Button>
    </div>
  );
}
