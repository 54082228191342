
import { Button, TextField } from "@mui/material"
import { MedalStar} from "iconsax-react"
import { useEffect } from "react"


export function RatingsQuestions({questions,closeModal}){
    return <div>

        <div>
            <Button>
                Prev
            </Button>

            <Button>
                next
            </Button>
        </div>
    </div>
}

function AratingQuestion(){
    
}

export function RatingsStars({saveRatings}){

    function handleStarClick(event){
        console.log(event.target.id);
        switch(true){
            case event.target.id == "5":{
                colorize(5)
                saveRatings(5)
            }
            break;
            case event.target.id == "4":{
                colorize(4);
                saveRatings(4)
            }
            break;
            case event.target.id == "3":{
                colorize(3);
                saveRatings(3)
            }
            break;
            case event.target.id == "2":{
                colorize(2);
                saveRatings(2)
            }
            break;
            case event.target.id == "1":{
                colorize(1);
                saveRatings(1)
            }
            break;
        }

    }

    function handleStarClick2(element_id){
        colorize(element_id);
        saveRatings(element_id);
    }


    function colorize(element){
        let maxValue = 5
        for (let index = 1; index <= maxValue ; index++) {
           let Element = document.getElementById(`${index}`);
           if (index <= element) {
               Element.classList.remove("text-slate-300");
               Element.classList.add("text-green-500");
           }else{
            Element.classList.remove("text-green-500");
            Element.classList.add("text-slate-300");
           }
        }
    }

    return <div className="w-screen justify-around p-2">
                <div className="flex w-full justify-around ">
                    <div onClick={()=>handleStarClick2(1)} className = "w-[20%]">
                        <MedalStar id="1" variant="Bold" className=" text-2xl w-[100%] h-[2.5rem] text-slate-300 " />
                    </div>

                    <div onClick={()=>handleStarClick2(2)} className = "w-[20%]">
                        <MedalStar id="2" variant="Bold" className=" text-2xl w-[100%] h-[2.5rem] text-slate-300  " />
                    </div>

                    <div onClick={()=>handleStarClick2(3)} className = "w-[20%]">
                        <MedalStar id="3" variant="Bold" className=" text-2xl w-[100%] h-[2.5rem] text-slate-300  " />
                    </div>

                    <div onClick={()=>handleStarClick2(4)} className = "w-[20%]">
                        <MedalStar id="4" variant="Bold" className=" text-2xl w-[100%] h-[2.5rem] text-slate-300  " />
                    </div>

                    <div onClick={()=>handleStarClick2(5)} className = "w-[20%]">
                        <MedalStar id="5" variant="Bold" className=" text-2xl w-[100%] h-[2.5rem] text-slate-300  " />
                    </div>

                </div>
    </div>
}

export function RatingReasons({handleChange}){
    return <TextField placeholder = "Reason" onChange = {(event)=>handleChange(event.target.value)} sx = {{width:"100%"}}  />
}