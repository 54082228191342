import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { TimePicker } from "@mui/x-date-pickers";
import { CloseCircle} from "iconsax-react";
import {FormControl, InputLabel,  MenuItem, Select } from "@mui/material";
import Damp from "./damp.jsx"
import CarModel from "./carModel.jsx";
import { useSearchParams } from "react-router-dom";



export default function Issue({sendCancelId,showdateNtime,handleBack,handleCancel,showdamp,handleRequestClick,usersCars,services,carBrands}){
    const [selectedCar,setSelectedCar] = useState('');
    const [model,setModel] = useState(null);
    const [service,setService] = useState('');
    const [description,setDescription] = useState('');
    const [showOthers,setShowOthers] = useState(false);
    const [value, setValue] =useState(dayjs());
    const [time, setTime] =useState(dayjs().format("hh:mm:ss"));
    const [date, setDate] =useState(dayjs().format('YYYY-MM-DD'));
    const [unfilledError,setUnfilledError] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(function(){
        if (searchParams.get("car")) {
            console.log("value of the car from the url is",Number(searchParams.get("car")));
            setSelectedCar(init=>Number(searchParams.get("car")))
        }
        if (searchParams.get("others")) {
            console.log("value of the car from the url is",Number(searchParams.get("car")));
            setShowOthers(init=>true)
        }
        if (searchParams.get("service")) {
            setService(init=>Number(searchParams.get("service")))
        }
        if (searchParams.get("desc")) {
            setDescription(init=>searchParams.get("desc").replace("+"," ","g"))
        }
    },[])

    function storeToUrl(key,value){
        return new Promise(function(resolve,reject){
            searchParams.append(key,value);
            setSearchParams(searchParams);
            console.log(key,value);
            return resolve();
        })
    }
    function ServiceStoreFunc(value){
        storeToUrl("service",value)
    }
    function CarStoreFunc(value){
        storeToUrl("car",value)
    }
    function removeUrlStore(key){
        return new Promise(function(resolve,reject){
            searchParams.delete(key)
            setSearchParams(searchParams)
            return resolve();
        })
    }
    function handleRequestMechanic(){
        const deriveModel = usersCars?.car_model
        if(selectedCar !=="" && service!=="" && description !=="" && (showOthers ? model !==null:true) ){
            if (showdateNtime) {
                    if ((time !== null)) {
                            handleRequestClick({car_brand_id:selectedCar,service_id:service,car_model:model || deriveModel,description:description,time:time,date:date})
                            sendCancelId(init=>usersCars.id)
                        
                    }else{
                        console.log("check your date and time");
                    }
            }else{
                storeToUrl("desc",description)
                .then(function(response){
                    handleRequestClick({car_brand_id:selectedCar,service_id:service,car_model:model || deriveModel,description:description})
                    sendCancelId(init=>usersCars.id)
                })
            }
        }else{
            setUnfilledError(init=>true);
            console.log("some required fields have not bee filled");
        }
    }
    async function handleOthers(shouldHandle){
        if (shouldHandle) {
            await removeUrlStore("car")
            storeToUrl("others","true")
            .then(function(){
                setShowOthers(()=>true)
            })
        }else{
            removeUrlStore("others")
            removeUrlStore("car")
            setShowOthers(init=>false)
        }
    }
     function handelCnclClick(){
        removeUrlStore("car")
        .then(async function(response){
            await removeUrlStore("others")
            await removeUrlStore("service")
            await removeUrlStore("desc")
            .then(function(response){
               handleCancel(init=>false)
            })
        })
    }

    return <div className={` justify-center rounded-t-3xl flex flex-col gap-2 absolute left-0 bottom-0 w-full max-h-screen overflow-y-scroll font-sharp p-4 bg-white`}>
                <p className="font-black text-xl">{`${showdateNtime ?"When would you like to schedule your request?":"Tell us about your car issue"}`}</p>
                <p className="text-slate-600">{`${showdateNtime ?"Scheduling your request ensures your timely assistance and smoother communication with our mechanics.":"Your response enables us to provide the necessary assistance."}`}</p>
                <IssueDropDown urlID={selectedCar} storeFunc={CarStoreFunc} indicateUnfilled={unfilledError ? !Boolean(selectedCar):false} reportInto={setSelectedCar} handleothers={handleOthers}  placeholder={"Select car brand"} identifier={"selectCar"} question={"Which car requires Service?"} options={usersCars?.car_brand?.name ? [{name:usersCars?.car_brand,model:usersCars?.car_model,id:usersCars?.car_brand.id},{name:"Others",model:"",id:"other"}]:[{name:"Others",model:"",id:"other"}]} />
                {showdateNtime && <div className="w-full"><p>Select Time</p><TimePicker value={value} onChange={(value)=>setTime(init=>value.format("hh:mm:ss"))}  style={{fontFamily:"SharpSans",width:"100%"}} sx={{width:"100%"}} /></div>}
                {showdateNtime && <div className="w-full"><p>Select Date</p><DatePicker value={value} onChange={(value)=>setDate(init=> value.format('YYYY-MM-DD'))} minDate={dayjs()}  sx={{width:"100%"}} /></div>}
                {showOthers && <IssueDropDown urlID={selectedCar} storeFunc={CarStoreFunc} indicateUnfilled={unfilledError ? !Boolean(selectedCar):false} reportInto={setSelectedCar} placeholder={"Select car brand"} identifier={"others"} question={"Others (optional)"} options={carBrands} />}
                {showOthers && <CarModel  indicateUnfilled={unfilledError ? !Boolean(model):false} setResult={setModel}/>}
                <IssueDropDown urlID={service} storeFunc={ServiceStoreFunc} indicateUnfilled={unfilledError ? !Boolean(service):false} reportInto={setService} placeholder={"Select a service"} identifier={"serviceType"} question={"Which type of service do you want?"} options={services} />
                <div className="w-full">
                    <p className="font-bold">Description</p>
                    <textarea value={description} onChange={(e)=>setDescription(init=>e.target.value)} className={`mt-1 bg-transparent  ${(unfilledError ? !Boolean(description):false) ? "border-red-700":"border-slate-500"} border-solid border-[0.02rem] rounded-md py-3 px-2 w-full`} placeholder="message"/>
                </div>
                <button onClick={handleRequestMechanic} className=" rounded-sm py-2 w-full bg-green-600  text-white pl-4">Request Mechanic</button>
                <button onClick={()=>{handelCnclClick()}} className=" rounded-sm py-2 w-full flex gap-1 "><CloseCircle className="text-orange-800"/>Cancel Request</button>
                <div onClick={()=>{removeUrlStore("address").then(function(response){handleBack(init=>false)})}}>{showdamp && <Damp/>}</div>
    </div>
}

 function IssueDropDown({urlID,storeFunc,indicateUnfilled,reportInto,handleothers=()=>{},placeholder,identifier,question,options=["Others"]}){

    useEffect(()=>{
        console.log("urlSearch",urlID);
        console.log("options",options[urlID-1]);
    },[urlID])

    function handleSelection(e){
        if (e.target.value === "other") {
            console.log("value is indeed others",e.target);
             handleothers(true);
        }else{
            handleothers(false);
        }
      console.log("selection made for ",identifier,"is",e.target.value);
      reportInto(init=> e.target.value === "other" ?'' :e.target.value)
      if (e.target.value !== "other") {
        storeFunc(e.target.value);
      }
    }

    return <div>
        <p className="mb-3">{question}</p>

        <FormControl fullWidth>
        <InputLabel style={{fontFamily:"SharpSans",fontWeight:"800",color:"rgb(203 213 225)", fontSize:"0.875rem"}} className="font-bold text-slate-300 text-sm" labelid={identifier}>{placeholder}</InputLabel>
            <Select value={urlID} error= {indicateUnfilled} style={{fontFamily:"SharpSans",outlineColor:"grey"}} className="font-bold bg-transparent" variant="outlined" onChange={(e)=>handleSelection(e)}  labelId={identifier} id={identifier}> 
                {
                    options.map(function(anOption){
                        return <MenuItem  value={anOption.id}  style={{fontFamily:"SharpSans"}} className="font-black flex gap-2" ><p className="font-semibold ">{anOption.name.name || anOption.name}</p><p>{anOption.model}</p></MenuItem>
                    })
                }
            </Select>
        </FormControl>
    </div>
}