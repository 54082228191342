import { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import { ArrowLeft2,CallCalling,Video } from "iconsax-react";
import placeholder from "../../../Images/1 1.png";
import { Send } from "iconsax-react";
import {db} from "../../../firebase";
import { query,orderBy,onSnapshot,limit,doc,setDoc,addDoc, collection, serverTimestamp, Timestamp } from "firebase/firestore";
import dayjs from "dayjs";

export default function SingleChat({handleBack,mechanicEmail,carOwnerEmail,mechanicName,carModel,profile_pic}){

    useEffect(function(){
    const userEmail = localStorage.getItem("userEmail");
    if(userEmail){
        setDoc(doc(db, "chats",`${carOwnerEmail}_${mechanicEmail}`), {
            createdAt:Timestamp.fromDate(new Date()),
            participants:[
                carOwnerEmail,
                mechanicEmail
            ]
          });   
    }else{
        console.log("you have no email please re login");
    }
    },[])

    return <div className="p-4 w-full h-screen flex flex-col bg-white">
                <HeaderSection handleBack={handleBack} profile_pic={profile_pic||placeholder} name={mechanicName} carModel={carModel}/>
                <BodySection carOwnerEmail={carOwnerEmail} mechanicEmail={mechanicEmail}/>
    </div>
}


function HeaderSection({handleBack,name,carModel,profile_pic}){
    return <div className="flex justify-between items-center mb-10">
        <div className="flex items-center gap-2">
            <ArrowLeft2 onClick={handleBack} className="text-slate-500"/>
            <img className="w-[10vw] h-[10vw] rounded-full" src={profile_pic} alt="users Profile pic"/>
            <div>
                <p className="font-bold">{name}</p>
                <p className="text-sm text-slate-500">{carModel}</p>
            </div>
        </div>
        <div className="flex gap-4">
            <CallCalling/>
            <Video/>
        </div>
    </div>
}

function BodySection({carOwnerEmail,mechanicEmail}){
    // const userEmail = localStorage.getItem("userEmail");
    const [inputvalue,setInputValue] = useState();
    const [messages,setMessages] = useState([]);
    useEffect(function(){
        const q = query(collection(db,"chats",`${carOwnerEmail}_${mechanicEmail}`,"messages"),
        orderBy("timestamp","asc"),
        limit(50));

        const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            const fetchedMessages = [];
            QuerySnapshot.forEach((doc) => {
              fetchedMessages.push({ ...doc.data(), id: doc.id });
            });
            const sortedMessages = fetchedMessages.sort(
              (a, b) => a.createdAt - b.createdAt
            );
            setMessages(sortedMessages);
          });
          return () => unsubscribe;
    },[])
    function handleInputChange(value){
        setInputValue(init=>value)
    }
    function sendAmessage(){
        const typeOfUser = localStorage.getItem("accountType")
        if ((carOwnerEmail) && (mechanicEmail) ) {
            addDoc(collection(db, "chats",`${carOwnerEmail}_${mechanicEmail}`,"messages"), {
            message:inputvalue,
            sender:typeOfUser == "mechanic"?mechanicEmail:carOwnerEmail,
            timestamp:Timestamp.fromDate(new Date())

          }).then(function(response){
              setInputValue(init=>"")
          }).catch(function(error){
            console.log(error);
          })
        }

    }

    return <div className="w-full h-[90%] flex flex-col justify-between relative">
         <p className="text-slate-500 text-[0.8rem]">
            Remember to excercise caution and discretion when sharing senitive information in this chat. Stay safe and keep your personal data secure
         </p>
         <Messages  messages={messages}/>
         <MessageSender value={inputvalue} handleChange={handleInputChange} handleSend={sendAmessage}/>
    </div>
}
function Messages({messages}){

    const bodyRef = useRef(null);
    const userEmail = localStorage.getItem("userEmail")
    useEffect(function(){
        if(bodyRef.current != null){
            console.log("scrolling");
            bodyRef.current.scroll({ top: bodyRef.current.scrollHeight, behavior: 'smooth' })
        }
    },[messages])

    return <div ref={bodyRef} className="relative w-full h-[75%] overflow-y-scroll flex flex-col gap-8 mt-12 pb-1">
        {
            messages.map(function(amessage){
                return <AMessage msgPackage={amessage} userEmail={userEmail}/>
            })
        }
    </div>
}                                                                                                                                                                                                                                                                                 
function AMessage({msgPackage,userEmail}){
    console.log(msgPackage);
    return <span className={`max-w-[55%] min-w-fit inline-block ${msgPackage?.sender == userEmail ?"self-start":"self-end"}`}>
                <p className={`${msgPackage?.sender == userEmail ? "bg-slate-200":"bg-green-200"} p-3 rounded-lg `}>
                    {msgPackage.message}
                </p>
                {msgPackage?.sender != userEmail && <p className="p-1 text-sm text-slate-600">{dayjs.unix(msgPackage.timestamp.seconds).format("hh:mm a")}</p>}
                
    </span>
}

function MessageSender({handleChange,value,handleSend}){
    return <div className="rounded-lg p-3 relative w-full  flex items-center justify-between border-solid border-[0.02rem] border-slate-500">
                <textarea value={value} onChange={(e)=>handleChange(e.target.value)} autofocus rows={2} className=" w-[80%] text-wrap outline-none " placeholder="Type your message here" />
                <Button onClick={handleSend} sx={{backgroundColor:"rgb(34 197 94)",position:"absolute",padding:"1rem 0.2rem",borderRadius:"999px"}} variant="contained" className=" absolute right-[5%] bg-green-500 rounded-full">
                    <Send />
                </Button>
    </div>
}
