import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { numberWithCommas } from "../utils";
import { format } from "date-fns";
import TableRowsSelect from "../TableSelectRows";

const isProd = window.location.hostname === "requestmechanic.com";

const baseUrl = `${
  isProd
    ? "https://requestnowmechanic.com/v1"
    : "https://requestnowmechanic.com/staging/v1"
}`;

export const Transactions = () => {
  const { email } = useParams();
  const [userTransactions, setUserTransactions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = parseInt(searchParams.get("limit")) || 10;
  const [currentPage, setCurrentPage] = useState(1);

  console.log(email);

  const fetchUserTransactions = async () => {
    const accessToken = localStorage.getItem("authAccessToken");

    try {
      const response = await axios.get(
        `${baseUrl}/client/user-transactions/?email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Transaction History Response:", response.data.data);
      setUserTransactions(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserTransactions();
  }, [email]);

  // Calculate total pages
  const totalPages = Math.ceil(userTransactions.length / limit);

  // Get the data for the current page
  const currentData = userTransactions.slice(
    (currentPage - 1) * limit,
    currentPage * limit
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (value) => {
    setSearchParams({ currentPage, limit: value });
    console.log(value);
  };

  return (
    <TransactionsView>
      <div className="overflow-x-auto">
        <table className="min-w-full border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-4 text-left">Reference</th>
              <th className="p-4 text-left">Transaction Type</th>
              <th className="p-4 text-left">Amount</th>
              <th className="p-4 text-left">Date</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((transaction) => (
            // {userTransactions.map((transaction) => (
              <tr key={transaction?.id} className="bg-white">
                <td className="p-4">{transaction?.reference}</td>
                <td className="p-4">{transaction?.transaction_type}</td>
                <td className="p-4">
                  ₦{numberWithCommas(transaction?.amount || "00.00")}
                </td>
                <td className="p-4">
                  {format(new Date(transaction?.date_created), "do MMM, yyyy")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PaginationView>
        <TableRowsSelect onChange={handleRowsPerPageChange} />

        <div className="paginate">
          <button
            className="prev"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <span>
            Page {currentPage} of {Math.ceil(userTransactions.length / limit)}
          </span>
          <button
            className="next"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </PaginationView>
    </TransactionsView>
  );
};

const TransactionsView = styled.div`
  margin: 0 1rem;
  height: 100%;
  /* padding-top: 2rem; */
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 42px;
      height: 42px;
      border: none;
      outline: none;
      background-color: rgb(209 213 219);
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: rgb(209 213 220);
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;