


import { useMediaDevice } from "@videosdk.live/react-sdk";
import {  useSearchParams } from "react-router-dom"
import Callz from "./component"
import { useEffect, useState } from "react";

export default function VideoCall(){
    const [searchParams,setSearchParams] = useSearchParams();

    async function hasCameraPermission(){
        return new Promise(function(resolve,reject){
            navigator.permissions.query({ name: "camera" })
            .then(function(result){
                resolve(result.state);
                return result;
            }).catch((error)=> {
                console.log(error);
                reject("an error occured")
            });
        })
    }

    function getCameraAndMicrophonePermision(){
                navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then((stream) => {
                    console.log("Access granted to microphone and camera");
                })
                .catch((error) => {
                    alert("you can't make video calls without granting permission to use your microphone and camera")
                });
    }
    useEffect(function(){
        hasCameraPermission()
        .then(function(result){
            if(result !== "granted"){
                getCameraAndMicrophonePermision();
            }
        })
        .catch(function(error){
          console.log(error);
        },[])
        })

    return <div>
        <Callz  mechanicEmail={searchParams.get("email")} mechanicName={searchParams.get("name")} mechanic_id={searchParams.get("mechanic_id")}
         token={searchParams.get("token")}
         carModel={searchParams.get("model")} profile_pic={searchParams.get("image")} mech_token={searchParams.get("mechtkn")} />
    </div>
}