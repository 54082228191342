import {MeetingProvider} from "@videosdk.live/react-sdk";
import { AudioControls,Counter,Biodata, AudioMents } from "./components"
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ArrowLeft2 } from "iconsax-react"

const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJmMDRmZDM1ZC00MzNlLTQzMmUtOGM0Yi1mNzdiNWIxZDI1YjEiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcyNjY3OTg2NywiZXhwIjoxNzU4MjE1ODY3fQ.o9w3qWNc3Y6caNnq5u6WAsc4Vx1l8sC6Pow9hhZtRnQ"

export default function MechAudioCallz(){
const [startTimer,setStartTimer] = useState(false);
const [searchParams, setSearchParams] = useSearchParams();
const meetingId = searchParams.get("id");
const name = searchParams.get("name");
const image = searchParams.get("image");
const model = searchParams.get("model");
const dur = searchParams.get("val");
const callerToken = searchParams.get("cllrtkn");

async function hasMicrophonePermission(){
    return new Promise(function(resolve,reject){
        navigator.permissions.query({ name: "microphone" })
        .then(function(result){
            resolve(result.state);
            return result;
        }).catch((error)=> {
            console.log(error);
            reject("an error occured")
        });
    })
  }
  
  function getMicrophonePermision(){
            navigator.mediaDevices.getUserMedia({audio: true})
            .then((stream) => {
                console.log("Access granted to microphone");
            })
            .catch((error) => {
                // alert("you can't make video calls without granting permission to use your microphone and camera")
            });
  }
  
  useEffect(function(){
  hasMicrophonePermission()
  .then(function(result){
      if(result !== "granted"){
        getMicrophonePermision();
      }
  })
  .catch(function(error){
    console.log(error);
  },[])
  })

function handleBack(){
    window.location.href = "/mechanic-dashboard";
}


    return <div className="w-screen h-screen p-4">
                {(authToken !=null && meetingId !=null ) && (<MeetingProvider config={{ meetingId:meetingId, micEnabled: true, webcamEnabled: false, name:name,multistream: true,}} reinitialiseMeetingOnConfigChange={true} token={authToken}>
                <div className="w-full" onClick={handleBack}><ArrowLeft2/></div>
                <div className="w-full h-[75%] flex flex-col justify-end gap-[8rem] items-center">
                    <div className="flex flex-col gap-[2rem] justify-center  items-center">
                        <Biodata  carmodel={model} image={image} name={name}/>
                        {startTimer && <Counter/>}
                    </div>
                    <AudioControls duration= {dur} meetingId={meetingId} callerToken={callerToken} />
                </div>
                <AudioMents startTimer={function(){setStartTimer(init=>true)}}/>
                </MeetingProvider>)}
    </div>
}
