

import { Call } from "iconsax-react"



export default function CallBufferScreen({acceptCall, declineCall,image,name}){
    return <div className="relative w-screen h-screen bg-gradient-to-br from-green-400 via-green-600 to-slate-700">
                <Biodata image={image} name={name} />
                <DecisionControls acceptCall={acceptCall} declineCall={declineCall} />
                <Blur/>
    </div>
}

function DecisionControls({acceptCall, declineCall}){
    return <div className="flex px-2 w-full fixed left-0 bottom-[10vh] justify-around z-10">

        <div onClick={declineCall} className="flex flex-col justify-center items-center">
            <div className="bg-red-600 w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
                <Call variant="Bold" className="text-white h-[2.5rem] w-[2.5rem]"/>
            </div>
            <p className="text-slate-50">Decline</p>
        </div>

        <div onClick={acceptCall} className="flex flex-col justify-center items-center">
            <div className="bg-green-500 w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
                <Call variant="Bold" className="text-white h-[2.5rem] w-[2.5rem]"/>
            </div>
            <p className="text-slate-50">Accept</p>
        </div>

    </div>
}

function Biodata({image,name}){
    return <div className="w-full flex z-10 gap-6 px-12 justify-start items-center relative top-[10%]" >
        <div className=" bg-slate-500 w-[10vh] h-[10vh] rounded-[50%] flex justify-center items-center overflow-hidden">
            <img alt={`this is ${name}`} src={image} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col w-[60%]">
            <p className="text-white text-[1.6rem] capitalize font-coolvetica w-full text-ellipsis overflow-hidden text-nowrap">{name}</p>
            <p className="text-slate-50 text-lg">Calling...</p>
        </div>
    </div>
}

function Blur(){
    return <div className="z-[-0] absolute top-0 left-0 w-full h-full bg-white opacity-10">

    </div>
}