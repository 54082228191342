import { useSearchParams } from "react-router-dom";
import Damp from "./damp"
import {  Calendar, SecurityTime} from "iconsax-react";


export default function Urgency({showdamp,handleBack, handleInstantClick,handleScheduleClick}){

    const [searchParams,setSearchParams] = useSearchParams();

    function removeUrlStore(key){
        return new Promise(function(resolve,reject){
            searchParams.delete(key)
            setSearchParams(searchParams)
            return resolve();
        })
    }

    function handelDampClick(){
        removeUrlStore("car")
        .then(function(response){
            removeUrlStore("service")
            .then(function(response){
                removeUrlStore("desc").then(function(response){handleBack(init=>false)})
            })
        })
    }

    return <div className=" flex flex-col gap-4 absolute left-0 bottom-0 w-full rounded-t-3xl font-dmsans bg-white p-4">
                <p className="font-black text-xl">How urgent is this request?</p>
                <p className="text-slate-600">Your response regarding urgency enables us to expedite the necessary assistance</p>
                <button onClick={handleInstantClick} className=" rounded-sm py-2 w-full bg-green-600 text-left text-white pl-4 flex gap-3"><SecurityTime className="text-white"/>Instant</button>
                <button onClick={handleScheduleClick}  className="rounded-sm py-2 w-full bg-slate-200 text-left pl-4 flex gap-3" ><Calendar/> Schedule</button>
                <div onClick={handelDampClick}>{showdamp && <Damp/>}</div>
    </div>
}