
import { useSearchParams } from "react-router-dom"
import roadxxsmall from "../../Images/road sign/road-sign xxsmall.png"
import roadxsmall from "../../Images/road sign/road-sign xsmall.png"
import roadsmall from "../../Images/road sign/road-sign small.png"
import roadmedium from "../../Images/road sign/road-sign medium.png"
import roadlarge from "../../Images/road sign/road-sign large.png"
import roadxlarge from "../../Images/road sign/road-sign xlarge.png"
import Damp from "./damp"

export default function LocationDecision({isLocating,handleBack,shouldamp,auto,handleManual}){

    const [searchParams,setSearchParams] = useSearchParams();

    function removeUrlStore(key){
        return new Promise(function(resolve,reject){
            searchParams.delete(key)
            setSearchParams(searchParams)
            return resolve();
        })
    }

    return <div  className="w-full font-dmsans p-4 bg-white flex flex-col items-center gap-2">
        <div className="w-full flex justify-center">
            <picture className="w-[30%]">
                <source srcSet={roadxlarge} media="(min-width: 1200px)"/>
                <source srcSet={roadlarge} media="(min-width: 960px)"/>
                <source srcSet={roadmedium} media="(min-width: 750px)"/>
                <source srcSet={roadsmall} media="(min-width: 450px)"/>
                <source srcSet={roadxsmall} media="(min-width: 350px)"/>
                <source srcSet={roadxxsmall} media="(min-width: 200px)"/>
                <img src={roadmedium} className="w-full h-auto"  />
            </picture>
        </div>
        <p className="font-black text-xl">Your Current Location?</p>
        <p className="text-slate-600 text-center w-[95%]">Providing your current location enables us to identify mechanics in your vicinity, offering you prompt assistance from proffessionals within reach</p>
        <button onClick={auto} className={` py-2 w-full ${isLocating && "bg-green-800"} bg-green-600 text-white`}>{isLocating?"Searching...":"Set Automatically"}</button>
        <button onClick={handleManual} className="py-2 w-full bg-slate-200" >Set Manually</button>
        <div onClick={()=>{removeUrlStore("urgency");handleBack(init=>false)}}>{shouldamp && <Damp/>}</div>
     </div>
}