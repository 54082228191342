import { Call } from "iconsax-react"
import {useMeeting, useParticipant,} from "@videosdk.live/react-sdk";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

const authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiI5MWY1MTkzZC1hODg0LTQzYWMtYWFmNS1iZTM2YjFmNmE2NWIiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTczMjkxODAyNCwiZXhwIjoxNzY0NDU0MDI0fQ.nj8TLX6NncO-bV4zKSfjc8IzDFL2YtzLrVGvTKyN7uI'

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

export async function createMeeting(token){
  const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
    method: "POST",
    headers: {
      Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiI5MWY1MTkzZC1hODg0LTQzYWMtYWFmNS1iZTM2YjFmNmE2NWIiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTczMjkxODAyNCwiZXhwIjoxNzY0NDU0MDI0fQ.nj8TLX6NncO-bV4zKSfjc8IzDFL2YtzLrVGvTKyN7uI',
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  }).then(function(response){
          console.log(response)
          return response
  }).catch(function(error){
        console.log(error)
  })
  const { roomId } = await res?.json();
  return roomId;
};
  

export  function AudioControls({handlePickup,handleHangUp}){
  const {end} = useMeeting()
    return <div className="w-[75%] flex justify-between ">
                <div onClick={handlePickup} className="bg-green-500 w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
                    <Call variant="Bold" className="text-white h-[2.5rem] w-[2.5rem]" />
                </div>

                <div onClick={function(){try{end(); window.history.back()}catch(error){console.log(error);window.history.back()}}} id="callender" className="bg-red-600 w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
                    <Call variant="Bold" className="text-white h-[2.5rem] w-[2.5rem]" />
                </div>
    </div>
}
export  function AudioControls2({room_id,mechanicToken,name,showCallRatingModal,stopTimer}){
  const {end,leave} = useMeeting({onParticipantJoined});
  const [isCallPicked,setIsCallPicked] = useState(false);

  function onParticipantJoined(){
    setIsCallPicked(init=>true)
  }

  function indicate_call_end_to_server(){
    const accessToken = localStorage.getItem("authAccessToken");
    if(accessToken){
      axios.post(`${baseUrl}/calls/end/`,{
        room_id
        },{
          headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
          }
      }).catch(function(error){
        console.log(error);
      })
    } 
}

function hangupNotifiermech(){
const ownerToken = localStorage.getItem("deviceToken")

  const accessToken = localStorage.getItem('authAccessToken');
    if(accessToken){
      axios.post(`${baseUrl}/client/send-message/`,{
          ownerName:name,
          meeting_id:room_id,
          call_token:authToken,
          owner_fcm:ownerToken,
          fcm_token:mechanicToken,
          is_call:false,
          is_voice:false,
          is_cancelled_call:true
    },{
        headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
        }
    }).catch(function(error){
      console.log("notification endpoint call error",error);
    })
  }
}

  function handleHangUp(){

    if(isCallPicked){
        try {
              end();
        }catch (error) {
      console.log(error);
    }
      stopTimer()
      indicate_call_end_to_server();
      hangupNotifiermech();
      showCallRatingModal()
    }else{
      hangupNotifiermech();
    }

  // insert rating open call here dot then call window.history.back
  // insert rating open call here
    // window.history.back()
  }


    return <div className="w-[75%] flex justify-center ">
                <div onClick={function(){handleHangUp()}} id="callender" className="bg-red-600 w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
                    <Call variant="Bold" className="text-white h-[2.5rem] w-[2.5rem]" />
                </div>
    </div>
}

export function Counter(){
const [seconds,setSeconds] = useState(0);
const [minute,setMinute] = useState(0);
const [hour,setHour] = useState(0);

function doubleFormat(value){
      if (String(value).length < 2) {
        return `0${value}`
      }else return value
}
    useEffect(function(){
      const interval = setInterval(function(){
          setSeconds(init=>{return init >= 59 ? 0:init +1})
      },1000)
  
      return function(){
        clearInterval(interval);
        
      }
    },[])
  
    useEffect(function(){
      const interval = setInterval(function(){
          setMinute(init=>{return init >= 59 ? 0:init +1}) 
            return function(){
              clearInterval(interval);
              
            }
      },60000);
    },[])
  
    useEffect(function(){
      const interval = setInterval(function(){
        setHour(init=>init++)
      },3600000)
  
      return function(){
        clearInterval(interval);
        
      }
    },[])
  
    return <div className="w-fit h-fit p-2 bg-slate-300 bg-opacity-60  rounded-lg">
      <p>{doubleFormat(hour)}:{doubleFormat(minute)}:{doubleFormat(seconds)}</p>
    </div>
}

export function Biodata({image,name,carmodel}){
    return <div className="flex flex-col justify-center gap-3 items-center">
                <div className="w-[8rem] h-[8rem] rounded-full overflow-hidden flex justify-center items-center">
                    <img src={image} />
                </div>
                <div className="flex flex-col gap-1 items-center justify-center">
                    <p className="font-bold text-2xl">{name}</p>
                    <p className=" text-slate-400 font-bold">{carmodel}</p>
                </div>
    </div>
}

export function AudioMents({startTimer,stopTimer,call_id,room_id,showCallRatingModal}){
  const [aParticipantJoined,setparticipantJoined] = useState(false);
  const [mechanicCallId, setMechanicCallID] = useState(null)
  const [participantLeft,setParticipantLeft] = useState(false);
  const {join, participants,end,leave,meeting} = useMeeting({
    onParticipantJoined,
    onParticipantLeft,
    
  });

  function indicate_call_end_to_server(){
      const accessToken = localStorage.getItem("authAccessToken");
      if(accessToken){
        axios.post(`${baseUrl}/calls/end/`,{
          room_id
          },{
            headers:{
              'Authorization':`Bearer ${accessToken}`,
              'Content-Type':'application/json'
            }
        }).catch(function(error){
          console.log(error);
        })
      } 
  }
  function indicate_call_answered_to_server(){
      const accessToken = localStorage.getItem("authAccessToken");
    if(accessToken){
      axios.patch(`${baseUrl}/calls/${call_id}/update/`,{
          status:"answered"
        },{
          headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
          }
      }).then(function(response){
              console.log(response);
      }).catch(function(error){
            console.log("an error occured in the indicate_call_answered_to_server function");
      })
    } 
  }
  function onParticipantJoined(participant){
    startTimer();
    setparticipantJoined(init=>true);
    setMechanicCallID(()=>participant.id)
  }
  function onParticipantLeft(){
    stopTimer();
    setParticipantLeft(init=>true)
    try {
      end();
  } catch (error) {
    console.log(error);
  }
  showCallRatingModal()
  // insert rating open call here dot then call window.hitory.back
    // window.history.back()
  }

  useEffect(function(){
    join()
  },[])

   
  useEffect(function(){
    if (participantLeft === true) {
      indicate_call_end_to_server()
    }
      
   },[participantLeft])
   

  useEffect(function(){
    if (aParticipantJoined === true) {
          indicate_call_answered_to_server()
    }
      
   },[aParticipantJoined])

  const participantOne = useParticipant([...participants.keys()][0])
  const participantTwo = useParticipant([...participants.keys()][1])


  return <>
            {participantOne && <AnAudio participantId={meeting?.localParticipant.id}/>}
            {mechanicCallId && <AnAudio participantId={mechanicCallId}/>}
  </>
}

function AnAudio({participantId}){
  const {webcamOn, micStream, micOn, isLocal} = useParticipant(participantId);
  const micRef = useRef(null);
  const { leave, toggleMic, toggleWebcam,disableWebcam} = useMeeting();
  useEffect(() => {
    // if(webcamOn){
    // }
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  useEffect(function(){
    disableWebcam()
    console.log("disaable webcam was just called");
  },[])

  return  <audio ref={micRef} autoPlay playsInline muted={isLocal} />

}